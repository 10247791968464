
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { SearchTerritoryByQueryDescription } from '@/settings/application/uses_cases/views/territories/SearchTerritoryByQueryDescription';
import { FindCommodityTypeByStatus } from '@/freight/application/commodity_type/search/FindCommodityTypesByStatus';
import { WeightFindAll } from '@/settings/application/uses_cases/weight/search/WeightFindAll';
import { Prop, Watch } from 'vue-property-decorator';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { Warehouse } from '@/settings/domain/warehouse/Warehouse';
import { WarehouseFindByStatus } from '@/settings/application/uses_cases/warehouse/search/WarehouseFindByStatus';
import { ConsecutiveFindByWarehouseAndType } from '@/courier/application/uses_cases/consecutive/search/ConsecutiveFindByWarehouseAndType';
import { PreRegisterTypeEnum } from '@/wms/domain/preRegister/PreRegisterTypeEnum';
import { ViewLocation } from '@/settings/domain/views/location/ViewLocation';
import VueBarcode from 'vue-barcode';
import { PackingUnits } from '@/settings/domain/packing_units/PackingUnits';
import { FindAllPackingUnitsByStatus } from '@/settings/application/uses_cases/packing_units/search/FindPackingUnitsByStatus';
import BillOfLandingAddForm from '../billOfLanding/BillOfLandingAddForm.vue';
import { ShippingMethod } from '@/settings/domain/shippingMethod/ShippingMethod';
import { FindByStatusShippingMethod } from '@/settings/application/uses_cases/shippingMethod/search/FindByStatusShippingMethod';
import { FindByStatusCommodityClass } from '@/settings/application/uses_cases/commodityClass/search/FindByStatusCommodityClass';
import { CommodityClass } from '@/settings/domain/commodityClass/CommodityClass';
import { TypeOperation } from '@/settings/domain/typeOperation/TypeOperation';
import { OperationFindAll } from '@/tracking/application/uses_cases/typeoperation/search/OperationFindAll';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';
import { CreateBillOfLanding } from '@/wms/application/billOfLanding/create/CreateBillOfLanding';
import { FindallBillOfLanding } from '@/wms/application/billOfLanding/search/FindallBillOfLanding';
import { TransportFindAll } from '@/tracking/application/uses_cases/typetransport/search/findall/TransportFindAll';
import { Transport } from '@/tracking/domain/typetransport/Transport';
import { FindAllTimelineConfigByParams } from '@/tracking/application/uses_cases/timelineConfig/search/findAll/FindAllTimelineConfigByParams';
import { TimelineConfig } from '@/tracking/domain/timelineConfig/TimelineConfig';
import AddCargoDetails from '@/freight/infrastructure/ui/quotation/form/addCargoDetails/AddCargoDetails.vue';
import { Containers } from '@/settings/domain/containers/Containers';
import { FindAllContainersByStatus } from '@/settings/application/uses_cases/containers/search/FindAllContainersByStatus';
import { OrderType } from '@/courier/domain/CourierOrder/OrderTypeEnum';
import { WmsOrderLine } from '@/courier/domain/orderline/WmsOrderLine';
import { WmsQuotationOrder } from '@/courier/domain/wmsQuotationOrder/WmsQuotationOrder';
import { WmsOrderFindByPk } from '@/courier/application/uses_cases/order/search/WmsOrderFindByPk';
import AppTimelineComponentFF from '@/tracking/infrastructure/ui/components/ff/timeline/TimelineComponentFF.vue';
import { FindAllTimelineConfigByPk } from '@/tracking/application/uses_cases/timelineConfig/search/findAll/FindAllTimelineConfigByPk';
import { Events } from '@/tracking/domain/events/Events';
import { OrderFindAllByTypeAndState } from '@/courier/application/uses_cases/order/search/OrderFindAllByTypeAndState';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { References } from '@/wms/domain/catalogs/references/References';
import { ReferenceFindAll } from '@/wms/application/catalogs/references/search/ReferenceFindAll';
import { ValidationObserver } from 'vee-validate';
import { TableOptions } from '@/core/domain/TableOptions';
import { generateIDByDate } from '@/core/plugins/FunctionUtil';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';

import html2pdf from 'html2pdf.js';
import { TypeDocument } from '@/settings/domain/TypeDocument/TypeDocument';
import { FindAllTypeDocument } from '@/settings/application/uses_cases/TypeDocument/search/FindAllTypeDocument';
import { FindAllStorebyWarehouse } from '@/wms/application/layout/store/search/FindAllStorebyWarehouse';
import { Store } from '@/wms/domain/layout/store/Store';
import { FindByNameConsignee } from '@/settings/application/uses_cases/consignee/search/FindByNameConsignee';
import { Consignee } from '@/settings/domain/consignee/Consignee';
import CargoTag from '@/wms/infrastructure/ui/warehousePreRegister/components/CargoTag.vue';
import { ViewLocationFindAllStoreAndCommodity } from '@/settings/application/uses_cases/views/location/search/ViewLocationFindAllStoreAndCommodity';
import { SearchGeneralPortByQuery } from '@/settings/application/uses_cases/generalPort/search/SearchGeneralPortByQuery';
import { GeneralPort } from '@/settings/domain/generalPort/GeneralPort';
import PickedBulksList from './components/PickedBulksList.vue';
import { FindPickedBulks } from '@/wms/application/bulk/search/FindPickedBulks';
import BranchSearch from '@/core/components/shared/BranchSearch.vue';
import { Branch } from '@/settings/domain/branch/Branch';
import SerialModal from '@/wms/infrastructure/ui/reference/modals/SerialModal.vue';
import { ReferenceFindByTimeline } from '@/wms/application/catalogs/references/search/ReferenceFindByTimeline ';
import WarehouseReceipt from '@/wms/infrastructure/ui/allDocuments/WarehouseReceipt.vue';
import CargoRelease from '@/wms/infrastructure/ui/allDocuments/CargoRelease.vue';
import { FindBulksForWarehouseReport } from '@/wms/application/bulk/search/FindBulksForWarehouseReport';
import { WarehouseBulk } from '@/wms/domain/bulk/WarehouseBulk';
import { FindAllPreparationsByStatus } from '@/freight/application/Preparation/search/FindAllPreparationsByStatus';
import { PreparationForList } from '@/freight/domain/Preparation/PreparationForList';
import { FindPreparationById } from '@/freight/application/Preparation/search/FindPreparationById';

interface Lists {
  containerList?: Containers[];
  originList?: Territories[];
  destinationList?: Territories[];
  commodityList?: CommodityType[];
  packagingList?: PackingUnits[];
  referencesList?: any[];
  typeOperationList?: TypeOperation[];
  locationList?: ViewLocation[];
  weightList?: any[];
  commodityClassesList?: CommodityClass[];
  customerList?: Entity[];
  billOfLandingList?: BillOfLanding[];
  singleReferencesList?: References[];
}

@Component({
  name: 'AddWarehousePreRegister',
  components: {
    CustomTableN,
    VueBarcode,
    BillOfLandingAddForm,
    AddCargoDetails,
    AppTimelineComponentFF,
    CargoTag,
    PickedBulksList,
    BranchSearch,
    SerialModal,
    WarehouseReceipt,
    CargoRelease
  }
})
export default class AddWarehousePreRegister extends Vue {
  @Inject(TYPES.API_VIEW_FIND_TERRITORIES_BY_QUERY)
  readonly searchTerritoriesByQueryParameter!: SearchTerritoryByQueryDescription;
  @Inject(TYPES.COMMODITY_TYPE_FIND_BY_STATUS)
  readonly findActiveCommodity!: FindCommodityTypeByStatus;
  @Inject(TYPES.FINDALL_WEIGHT)
  readonly findAllWeightUnits!: WeightFindAll;
  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE)
  readonly findEntityByDescription!: EntityFindFilter;
  @Inject(TYPES.FINDBYSTATUS_WAREHOUSE)
  readonly findActiveWarehouses!: WarehouseFindByStatus;
  @Inject(TYPES.FINDBYWAREHOUSEANDTYPE_CONSECUTIVE)
  readonly consecutiveFindByWarehouseAndType!: ConsecutiveFindByWarehouseAndType;
  @Inject(TYPES.VIEW_FIND_LOCATIONS_BY_WAREHOUSE_AND_COMMODITY)
  readonly findLocationsByWarehouseAndCommodity!: ViewLocationFindAllStoreAndCommodity;
  @Inject(TYPES.FIND_PACKING_UNITS_BY_STATUS)
  readonly findActivePackingUnits!: FindAllPackingUnitsByStatus;
  @Inject(TYPES.SHIPPING_METHOD_FIND_BY_STATUS)
  readonly findActiveShippingMethods!: FindByStatusShippingMethod;
  @Inject(TYPES.COMMODITY_CLASS_FIND_BY_STATUS)
  readonly findActiveCommodityClass!: FindByStatusCommodityClass;
  @Inject(TYPES.FINDALL_TYPE_OPERATION)
  readonly findTypeOperations!: OperationFindAll;
  @Inject(TYPES.BILL_OF_LANDING_SAVE)
  readonly saveBillofLanding!: CreateBillOfLanding;
  @Inject(TYPES.BILL_OF_LANDING_FINDALL)
  readonly findAllBillOfLanding!: FindallBillOfLanding;
  @Inject(TYPES.FINDALL_TRANSPORT)
  readonly findTransport!: TransportFindAll;
  @Inject(TYPES.TIMELINECONFIG_TYPE_FINDALL_BY_PARAMS)
  readonly findTimelines!: FindAllTimelineConfigByParams;
  @Inject(TYPES.FIND_CONTAINER_BY_STATUS)
  readonly searchActiveContainers!: FindAllContainersByStatus;
  @Inject(TYPES.ORDER_FIND_ALL_BY_TYPE_AND_STATE)
  readonly findApprovedQuotations!: OrderFindAllByTypeAndState;
  @Inject(TYPES.WMS_ORDER_FIND_BY_PK)
  readonly findWmsOrderByPk!: WmsOrderFindByPk;
  @Inject(TYPES.TIMELINECONFIG_TYPE_FINDALL_BY_PK)
  readonly findTimelineConfigByPk!: FindAllTimelineConfigByPk;
  @Inject(TYPES.FINDALL_REFERENCE)
  readonly findAllReferences!: ReferenceFindAll;
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly findByIdCompany!: CompanyFindById;
  @Inject(TYPES.TYPE_DOCUMENT_FIND_ALL)
  readonly findAllTypeDocument!: FindAllTypeDocument;
  @Inject(TYPES.FIND_ALL_STORE_WAREHOUSE)
  readonly findAllStoreWarehouse!: FindAllStorebyWarehouse;
  @Inject(TYPES.CONSIGNEE_FIND_BY_NAME)
  readonly findConsigneeByName!: FindByNameConsignee;
  @Inject(TYPES.GENERAL_PORTS_FIND_BY_QUERY)
  readonly findPortsByQuery!: SearchGeneralPortByQuery;
  @Inject(TYPES.BULK_FIND_PICKED)
  readonly findPickedBulks!: FindPickedBulks;
  @Inject(TYPES.TIMELINE_REFERENCES_FIND_BY_TIMELINE)
  readonly findReferencesByTimeline!: ReferenceFindByTimeline;
  @Inject(TYPES.BULKS_FIND_DATA_FOR_WAREHOUSE_RECEIPT_REPORT)
  readonly findDataForWarehouseReceipt!: FindBulksForWarehouseReport;
  @Inject(TYPES.CARGO_DETAILS_PRESET_FIND_STATUS)
  readonly findPreparationByStatus!: FindAllPreparationsByStatus;
  @Inject(TYPES.CARGO_DETAILS_PRESET_FIND_BY_ID)
  readonly findPreparationById!: FindPreparationById;

  //Listas
  originList: Territories[] = [];
  destinationList: Territories[] = [];
  customerList: Entity[] = [];
  commodityList: CommodityType[] = [];
  weightList: any[] = [];
  warehouseList: Warehouse[] = [];
  locationList: ViewLocation[] = [];
  packingList: PackingUnits[] = [];
  shippingMethodList: ShippingMethod[] = [];
  commodityClassesList: CommodityClass[] = [];
  typeOperationList: TypeOperation[] = [];
  billOfLandingList: BillOfLanding[] = [];
  typeTransportList: Transport[] = [];
  timelinesList: TimelineConfig[] = [];
  quotationsList: WmsQuotationOrder[] = [];
  storeByWarehouseList: Store[] = [];
  consigneeList: Consignee[] = [];
  generalPortsList: GeneralPort[] = [];
  bulksPicked: Bulk[] = [];
  asociatedReferences: References[] = [];
  preRegisterData: PreRegister = new PreRegister();
  bulksForWarehouseReceipt: WarehouseBulk | null = null;
  preRegisterPreparations: PreparationForList[] = [];

  //Listas
  lists: Lists = {
    containerList: [],
    originList: [],
    destinationList: [],
    commodityList: [],
    packagingList: [],
    referencesList: [],
    typeOperationList: [],
    locationList: [],
    weightList: [],
    commodityClassesList: [],
    billOfLandingList: [],
    customerList: [],
    singleReferencesList: []
  };

  //Otra data
  referenceSelected: References | null = null;
  eventList: Events[] = [];
  isLoading = false;
  bulkQuantity = 1;
  bulkGroup = 1;
  openTags = true;
  selectedEvent = 0;
  bulkForBarcodeModal: {
    index: 0;
    bulk: Bulk | null;
  } = {
    index: 0,
    bulk: null
  };
  activeTab = 0;
  typeDocumentList: TypeDocument[] = [];
  logoImg = localStorage.getItem('urlLogoCompany') as string;
  showModal = false;
  warehouseProcess = '';

  companyName = '';
  companyAdress = '';
  companyPhoneNumber = '';
  companyEmail = '';
  companyZipCode = '';
  showButtonIfNotPreRegister = false;

  //Forms items
  forms = {
    newBulk: new Bulk().setBulkType('bulk'),
    newContainer: new Bulk().setBulkType('container'),
    newBox: new Bulk().setBulkType('box'),
    newReference: new Bulk().setBulkType('reference')
  };

  formsForView = {
    newBulk: new Bulk(),
    newContainer: new Bulk(),
    newBox: new Bulk(),
    newReference: new Bulk()
  };

  //Form BL
  formBL: BillOfLanding = new BillOfLanding();

  /** Form helpers */

  senderAddress = '';
  quotationForLoad: WmsQuotationOrder | null = null;
  PRE_REGISTER_TYPE = PreRegisterTypeEnum.PRE_REGISTER;
  preparationForLoad: PreparationForList | null = null;

  //Picking actions
  pickingActions = {
    customActions: [
      {
        title: `${this.$t('general.pick')}`,
        action: (item: Bulk) => {
          this.form.bulks.push(item);
        },
        icon: 'fa fa-check',
        variant: 'success',
        condition: (item: Bulk) => {
          !this.form.bulks.some(bulk => bulk.serial === item.serial);
        }
      },
      {
        title: `${this.$t('general.remove')}`,
        action: null,
        icon: 'fa fa-remove',
        variant: 'danger',
        condition: (item: Bulk) => {
          return this.form.bulks.some(bulk => bulk.serial === item.serial);
        }
      }
    ]
  };

  //PROPS
  @Prop({ required: true }) form!: PreRegister;
  @Prop({ required: true }) componentType!: number;
  @Prop({ required: false }) actions!: any;
  @Prop({ required: false }) forView!: boolean;
  @Prop({ required: false }) module!: string;
  @Prop({ required: false }) listRoute!: string;
  @Prop({ required: false }) switchList!: Array<string>;

  /*
  Objeto de acciones para la tabla
  **/
  tableActions = {
    delete: this.deleteBulks,
    edit: this.loadBulk,
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        action: this.loadBulk,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        disabled: (item: Bulk) => item.fromQuotation
      },
      {
        title: `${this.$t('general.serialNumber')}`,
        icon: 'fa fa-file',
        variant: 'primary',
        action: (row: any) => this.warehouseProcessValue(row)
      },
      { title: `${this.$t('general.details')}`, icon: 'fa fa-eye', variant: 'warning', action: this.showItem },
      {
        title: `${this.$t('general.barCode')}`,
        action: this.showBarcode,
        icon: 'fa fa-barcode'
      },
      {
        title: `${this.$t('general.delete')}`,
        action: this.deleteBulks,
        icon: 'fa fa-trash',
        variant: 'danger'
      }
    ]
  };

  //Referencias de objetos
  $refs!: {
    barCodeModal: HTMLFormElement;
    blCreation: HTMLFormElement;
    requestInfoP1: InstanceType<typeof ValidationObserver>;
    requestInfoP2: InstanceType<typeof ValidationObserver>;
  };

  //VARIABLES PARA INTERVALO DE BUSQUEDA
  timeoutOrigin: any;
  timeoutDestination: any;
  timeoutEntity: any;
  timeoutConsignee!: any;
  searchInterval = 600;

  /**
   * Objeto de acciones para modal de crear BL
   * */
  actionsModal = {
    save: this.saveBL,
    clear: this.clearBl
  };

  /**
  * Funcion invocada cuando el componente es montado en el DOM
  * Verifica si el componente está siendo renderizado sólo para visualizar datos, de ser así no hace fetch de la data que se carga a los multiselect
  @returns void
  */
  mounted(): void {
    !this.forView && this.getAllData();
    this.dynamicWarehouseProcessValue();
    this.showButton();
  }

  /**
   * Funcion que llama toda la info de la empresa en el modal
   */
  created(): void {
    this.getCompanyInfo();
  }
  /**
   * Funcion invocada para obtener la data necesaria en las listas
   * @returns void
   */
  getAllData() {
    this.getCommodities();
    this.getWeihgtUnits();
    this.findWarehouses();
    this.findPackingUnits();
    this.findShippingMethods();
    this.findCommodityClasses();
    this.getTypeOperation();
    this.findAllTransport();
    this.getContainers();
    this.getPackingUnits();
    this.findQuotations();
    this.findReferences();
    this.getCompanyInfo();
    this.getTypeDocument();
    this.findPreparations();
  }

  // ** Funcion para definir si un vessel es requerido o no
  vesselRequired = 'unrequired';
  isRequired() {
    return this.vesselRequired === 'required';
  }

  /**
   * Getter para obtener una lista de cotizaciones filtradas por tipo de operacion y tipo de transporte
   * @author San7iix
   * @returns {Array<WmsQuotationOrder>}
   */
  get filteredQuotations(): Array<WmsQuotationOrder> {
    if (this.form.typeOperationData == null || this.form.typeTransport == null) return [];
    return this.quotationsList.filter(item => {
      return (
        item.typeOperationData?.code == this.form.typeOperationData?.code &&
        item.typeTransport?.id == this.form.typeTransport?.id
      );
    });
  }

  /**
   * Getter para obtener los campos a mostra en la tabla
   * @returns { Array<TableOptions> }
   */
  get fields(): TableOptions[] {
    return [
      {
        label: `#`,
        field: 'number'
      },
      {
        label: `${this.$t('general.reference')}`,
        field: 'reference',
        formatFn: (item: References) => (item ? `${item.code} - ${item.filterBrand}` : '-')
      },
      {
        label: `Serial`,
        field: 'serial',
        formatFn: (item: number) => (!item ? 'No generado' : item)
      },
      {
        label: `${this.$t('general.description')}`,
        field: 'shippingDetails.productName'
      },
      {
        label: `${this.$t('general.commodity')}`,
        field: 'shippingDetails.commodity.description'
      },
      {
        label: `${this.$t('general.class')}`,
        field: 'commodityClass',
        formatFn: (item: CommodityClass) => (item ? item.description : `-`)
      },
      {
        label: `${this.$t('general.location')}`,
        field: 'location',
        formatFn: (item: ViewLocation) => (item ? item.locationName : `-`)
      },
      {
        label: `${this.$t('general.dimentions')}`,
        field: (row: Bulk) => `${row.length}x${row.width}x${row.height}`
      },
      {
        label: `${this.$t('general.weight')}`,
        field: 'weight'
      },
      {
        label: `${this.$t('general.weightUnit')}`,
        field: 'weightUnit',
        formatFn: (item: any) => {
          return item ? item.description : `-`;
        }
      },
      {
        label: `${this.$t('general.packaging')}`,
        field: (item: Bulk) => {
          if (item?.containerType) return item.containerType?.description;
          return item.packaging
            ? item.packaging?.unit?.description
            : item.packingUnit
            ? item.packingUnit.description
            : '-';
        }
      },
      {
        label: `${this.$t('general.type')}`,
        field: 'quotationTypeLine'
      },
      {
        label: `${this.$t('general.containerType')}`,
        field: (item: Bulk) => {
          return item.dispatchContainer ? item.dispatchContainer.description : '-';
        }
      },
      {
        label: `${this.$t('general.shipper')}`,
        field: 'newShipper'
      },
      {
        label: `${this.$t('general.consignee')}`,
        field: 'newConsignee'
      },
      {
        label: `${this.$t('general.quantity')}`,
        field: 'quantity',
        hidden: this.forView
      },
      {
        label: `${this.$t('general.actions')}`,
        field: 'actions',
        hidden: this.forView
      }
    ];
  }

  /**
   * Getter para obtener los parametros de la URL
   */
  get urlParams() {
    return {
      query: this.$route.query
    };
  }

  /**
   * Getter para obtener el tipo de proceso que está seleccionado
   */
  get processTypeSelect() {
    if (this.form.typeOperationData == null) return 0;
    return this.form.typeOperationData.processType;
  }

  /**
   * Setter para asignar un valor al compo TypeOperation del formulario
   * @param newValue {number}
   */
  set processTypeSelect(newValue: number) {
    this.form.typeOperation = newValue;
  }

  get partialPreRegisterState() {
    return this.form.state?.includes('partial');
  }

  //Getter para obtener una decision de mostrar o no el form de bultos.
  get showFormBulks() {
    return !this.form.type?.includes('WR');
  }

  //Getter para obtener los tipos de operaciones y si descripcion
  get typeOperationOptions() {
    return [
      {
        value: 1,
        html: `<strong>${this.$t('general.importation')}</strong><p>${this.$t('quotation.importationDescription')}</p>`
      },
      {
        value: 2,
        html: `<strong>${this.$t('general.exportation')}</strong><p>${this.$t('quotation.importationDescription')}</p>`
      },
      {
        value: 3,
        html: `<strong>${this.$t('general.localOperation')}</strong><p>${this.$t(
          'quotation.importationDescription'
        )}</p>`
      }
    ];
  }

  //Getter para obtener un booleano para deshabilitar los inputs
  get isDisabled() {
    return this.componentType != 1 || this.forView;
  }

  //Getter para obtener el telefono principal del cliente
  get mainContactPhone() {
    if (this.form.sender == null || !('contacts' in this.form.sender)) return '';
    const mainContact = this.form.sender.contacts.filter(item => item.mainContact);
    return mainContact.length > 0 ? mainContact[0].phone : '';
  }

  //Getter para obtener la cantidad de grupos de bultos que existen en la lista
  get bulkGroupNumber() {
    if (this.form.bulks.length < 1) return 0;
    return Math.max(...this.form.bulks.map(bulk => bulk.group));
  }

  //Getter para obtener la user data desde localstorage
  get userData() {
    return `${localStorage.getItem('customerId') as string} - ${localStorage.getItem('userNameCompany') as string}`;
  }

  get bulksSorted() {
    return this.form.bulks.sort((a, b) => (a.sequence - b.sequence) * a?.group);
  }

  get newConsigneeList() {
    return this.form.bulks.map(bulk => bulk.newConsignee).filter((value, index, self) => self.indexOf(value) === index);
  }

  //Funcion invocada para agregar un bulto
  addBulk(item: Bulk, quantity: number, form: string) {
    const bulkNewConsignee = item.newConsignee;

    if (
      this.form.bulks.some(bulk => bulk.newConsignee == bulkNewConsignee) &&
      !['reference', 'pallet'].includes(item.cargoType)
    ) {
      this.$swal.fire({
        title: `${this.$t('general.warning')}`,
        text: `${this.$t('general.consigneeAlreadyExists')}`,
        icon: 'warning'
      });
      return;
    }
    const newBulk = item;
    newBulk.quotationTypeLine = form;
    newBulk.group = this.bulkGroupNumber + 1;
    const bulksNumber = this.form.bulks.length + 1;

    if (!item.reference) {
      for (let index = 0; index < quantity; index++) {
        const newBulkCopy = Object.assign({}, item);
        newBulkCopy.number = bulksNumber + index;
        this.form.bulks.push(newBulkCopy);
      }
    } else {
      this.form.bulks.push(item);
    }

    this.clearBulkForm(form, item);
  }

  //Funcion invocada para eliminar un grupo de bultos
  deleteBulks(item: any) {
    this.form.bulks = this.form.bulks.filter(bulk => {
      return item.group != bulk.group;
    });
    this.reorderBulks();
  }

  //Funcion invocada para reordenar numeros de bultos
  reorderBulks() {
    this.form.bulks.forEach((item, index) => (item.number = index + 1));
  }

  //Funcion invocada para editar un bulto de la lista
  loadBulk(item: Bulk) {
    const itemQuantity = this.form.bulks.filter(bulk => bulk.group == item.group).length;
    switch (item.quotationTypeLine) {
      case 'container':
        this.forms.newContainer = Object.assign(new Bulk(), { ...item, quantity: itemQuantity });
        break;
      case 'box':
        this.forms.newBox = Object.assign(new Bulk(), { ...item, quantity: itemQuantity });
        break;
      case 'bulk':
        this.forms.newBulk = Object.assign(new Bulk(), { ...item, quantity: itemQuantity });
        break;
      case 'reference':
        this.forms.newReference = Object.assign(new Bulk(), { ...item, quantity: itemQuantity });
        break;
      default:
        return;
    }
    this.deleteBulks(item);
    this.$toasted.show(
      `${this.$t('general.loadedToSection', { section: this.$t(`general.${item.quotationTypeLine}`) })}`
    );
  }

  //Funcion invocada para limpiar el formulario de adicionar bulto
  clearBulkForm(form?: string, item: Bulk = new Bulk()) {
    // Guardamos la información de consignee, shipper y customer para evitar ponerla otra vez
    const newItem = Object.assign({}, item);
    const { newConsignee, newShipper, customer, finalDestination } = newItem;
    switch (form) {
      case 'container':
        this.forms.newContainer = new Bulk().setBulkType('container');

        this.forms.newContainer.newConsignee = newConsignee;
        this.forms.newContainer.newShipper = newShipper;
        this.forms.newContainer.customer = customer;
        this.forms.newContainer.finalDestination = finalDestination;
        break;
      case 'bulk':
        this.forms.newBulk = new Bulk().setBulkType('bulk');
        this.forms.newBulk.newConsignee = newConsignee;
        this.forms.newBulk.newShipper = newShipper;
        this.forms.newBulk.customer = customer;
        this.forms.newBulk.finalDestination = finalDestination;
        break;
      case 'box':
        this.forms.newBox = new Bulk().setBulkType('box');
        this.forms.newBox.newConsignee = newConsignee;
        this.forms.newBox.newShipper = newShipper;
        this.forms.newBox.customer = customer;
        this.forms.newBox.finalDestination = finalDestination;
        break;
      case 'reference':
        this.forms.newReference = new Bulk().setBulkType('reference');
        this.forms.newReference.newConsignee = newConsignee;
        this.forms.newReference.newShipper = newShipper;
        this.forms.newReference.customer = customer;
        this.forms.newReference.finalDestination = finalDestination;
        break;
      default:
        this.forms = {
          newBulk: new Bulk().setBulkType('bulk'),
          newContainer: new Bulk().setBulkType('container'),
          newBox: new Bulk().setBulkType('box'),
          newReference: new Bulk().setBulkType('reference')
        };
        break;
    }
  }

  // Este método es llamado cuando el usuario escribe sobre el input de origen o destino para buscar territorios.
  findTerritoriesFactory(query: string, input: string, delayed = true): void {
    //Limpiamos cualquier función anterior en el timeout para evitar búsquedas repetitivas y solapadas.
    delayed ? clearTimeout(this.timeoutOrigin) : null;
    this.timeoutOrigin = setTimeout(
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      async () => {
        if (query.length >= 3) {
          try {
            const res = await this.findTerritories(query);
            input == 'origin' ? (this.originList = res) : (this.destinationList = res);
          } catch (error) {
            this.isLoading = false;
            throw new Error(`${error}`);
          }
        }
        clearTimeout(this.timeoutOrigin);
      },
      delayed ? this.searchInterval : 0
    );
  }

  //Metodo general de busqueda de territorios
  async findTerritories(query: string): Promise<Territories[]> {
    this.isLoading = true;
    const res = await this.searchTerritoriesByQueryParameter.execute(query.toUpperCase());
    this.isLoading = false;
    return res;
  }

  //Funcion invocada para buscar entidades basadas en el nombre
  findEntity(query: string) {
    try {
      clearTimeout(this.timeoutEntity);
      if (query.length < 2) return;
      const params: any = {
        text: query,
        type: ['SUPPLIER', 'CUSTOMER', 'SERVICE', 'OTHERS']
      };
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      this.timeoutEntity = setTimeout(async () => {
        this.isLoading = true;
        const res = await this.findEntityByDescription.execute(params);
        this.customerList = res.length > 0 ? res : [];
        this.isLoading = false;
        clearTimeout(this.timeoutEntity);
      }, this.searchInterval);
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para hacer set de la direccion del remitente
  async setAddress(input: Entity) {
    this.form.address.description = `${input.address.description}`;
    this.form.address.zipCode = input.address.zipCode;
    const res = await this.findTerritories(input.address.cityName);
    this.form.address.territory = res.filter(item => item.city == input.address.city)[0];
  }

  //Funcion invocada para obtener todos los almacenes activos
  async findWarehouses() {
    try {
      this.isLoading = true;
      const res = await this.findActiveWarehouses.execute(true);
      this.warehouseList = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para obtener el consecutivo del documento al seleccionar un almacen
  async getConsecutive(input: Warehouse) {
    try {
      this.isLoading = true;
      const res: {
        createAt: string;
        nextNumber: string;
        type: string;
        updateAt: string;
      } = (await this.consecutiveFindByWarehouseAndType.execute({
        type: `${input.code}_${PreRegisterTypeEnum.PRE_REGISTER}`,
        warehouse: input.code
      })) as any;
      this.form.number = res.nextNumber + 1;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para asignar un bulto al objeto bulkForBarcodeModal y mostrar el modal
  showBarcode(bulk: Bulk) {
    this.bulkForBarcodeModal.bulk = Object.assign({}, bulk);
    this.$refs.barCodeModal.show();
  }

  //Funcion invocada para validar
  handdleOk() {
    return;
  }

  //Funcion invocada para obtener la lista de Embalajes
  async findPackingUnits() {
    try {
      this.isLoading = true;
      const res = await this.findActivePackingUnits.execute(true);
      this.packingList = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para obtener la lista de modalidades de envio
  async findShippingMethods() {
    try {
      this.isLoading = true;
      const res = await this.findActiveShippingMethods.execute(true);
      this.shippingMethodList = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para buscar los tipos de operaciones y cargar el maestro
  async getTypeOperation() {
    try {
      this.isLoading = true;
      const res = await this.findTypeOperations.execute();
      this.typeOperationList = res.length > 0 ? (res as any) : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para guardar un BL en la base de datos
  async saveBL() {
    try {
      this.isLoading = true;
      await this.saveBillofLanding.execute(this.formBL);
      this.findAllBL();
      this.clearBl();
      this.$refs.blCreation.hide();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para limpiar el formulario de BL
  clearBl() {
    this.formBL = new BillOfLanding();
  }

  //Funcion invocada para obtener todos los metodos de transporte
  async findAllTransport() {
    try {
      this.isLoading = true;
      const res = await this.findTransport.execute();
      this.typeTransportList = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para validar un input
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  //Funcion invocada para obtener los timeline que aplican al tipo de registro que se está realizando
  async getTimelines(input?: Transport) {
    try {
      this.isLoading = true;
      const res = await this.findTimelines.execute({
        configFor: this.module === 'wms' ? 'w' : 'c',
        typeOperation: this.form.typeOperationData?.code,
        typeTransport: input?.id || this.form.typeTransport?.id
      });
      this.timelinesList = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para buscar containers activos
  async getContainers() {
    try {
      this.isLoading = true;
      const res = await this.searchActiveContainers.execute(true);
      res.length > 0 ? (this.lists.containerList = res) : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para buscar los commodity activos
  async getWeihgtUnits() {
    try {
      this.isLoading = true;
      const res = await this.findAllWeightUnits.execute();
      this.lists.weightList = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  //Funcion invocada para buscar los commodity activos
  async getCommodities() {
    try {
      this.isLoading = true;
      const res = await this.findActiveCommodity.execute(true);
      this.lists.commodityList = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  //Funcion invocada para obtener todos los BL creados
  async findAllBL() {
    try {
      this.isLoading = true;
      const res = await this.findAllBillOfLanding.execute();
      this.lists.billOfLandingList = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para buscar todos las unidades de peso
  async getPackingUnits() {
    try {
      this.isLoading = true;
      const res = await this.findActivePackingUnits.execute(true);
      res.length > 0 ? (this.lists.packagingList = res) : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para obtener la lista de clases de commodity de envio
  async findCommodityClasses() {
    try {
      this.isLoading = true;
      const res = await this.findActiveCommodityClass.execute(true);
      this.lists.commodityClassesList = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para obtener todas las cotizaciones (Deberian ser las cotizaciones aprobadas)
  async findQuotations() {
    try {
      this.isLoading = true;
      const payload = {
        type: OrderType.freightquotation,
        state: 'approved',
        page: 0
      };
      const res = await this.findApprovedQuotations.execute(payload);
      this.quotationsList = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para obtener una cotizacion a partir del tipo y numero de esta misma
  async findQuotationByPk() {
    try {
      if (!this.quotationForLoad) return;
      this.isLoading = true;
      const res = await this.findWmsOrderByPk.execute({
        number: this.quotationForLoad.number,
        type: this.quotationForLoad.typeOrder
      });
      if (!('error' in res)) {
        this.form.bulks = this.form.bulks.concat(this.convertLinesToBulks(res.lines, res));
        this.form.bulks = this.form.bulks.map(bulk => {
          if (bulk.owner) bulk.owner.code = generateIDByDate();
          return bulk;
        });
        this.quotationForLoad = null;
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para convertir los lines de una orden a bultos
  convertLinesToBulks(lines: WmsOrderLine[], quotation: WmsQuotationOrder): Bulk[] {
    return lines
      .filter(line => line.quotationTypeLine != 'additional')
      .map(line => {
        let newBulk = new Bulk();
        newBulk = Object.assign(newBulk, line);
        newBulk.group = this.bulkGroupNumber + 1;
        newBulk.fromQuotation = true;
        newBulk.owner = quotation.customer;
        newBulk.cargoTo = quotation.thirdParty;
        return newBulk;
      });
  }

  //Funcion invocada para ejecutar un save y luego hacer un fetch de la data que alimenta las listas
  async save() {
    const validated = (await this.$refs.requestInfoP1.validate()) && (await this.$refs.requestInfoP2.validate());
    if (!validated) return;
    const res = await this.actions.save();
    if (res) this.getAllData();
  }

  //Funcion para obtener los eventos del timeline seleccionado
  async getTimelineDetails(input: TimelineConfig) {
    try {
      this.isLoading = true;
      const res = await this.findTimelineConfigByPk.execute(input.id);
      this.eventList = 'id' in res ? res.events : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    } finally {
      this.selectedEvent = 0;
      this.findTimelineReferences(input);
    }
  }

  messageSingleMethod(input: ShippingMethod) {
    if (!input.multiple) {
      // this.$swal({
      //   title: `${this.$t('general.warning')}`,
      //   text: `${this.$t('general.messageSingleMethod')}`,
      //   icon: 'warning'
      // });
    }
  }

  //Funcion invocada para obtener todas las referencias
  async findReferences() {
    try {
      this.isLoading = true;
      const res = await this.findAllReferences.execute();
      this.lists.singleReferencesList = res ?? [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  get showSerialModal() {
    if (this.showModal) {
      this.$bvModal.show('mdlSerialModal');
    } else {
      this.$bvModal.hide('mdlSerialModal');
    }
    return this.showModal;
  }

  showItem(item: WmsOrderLine) {
    this.formsForView = {
      newBulk: new Bulk(),
      newContainer: new Bulk(),
      newReference: new Bulk(),
      newBox: new Bulk()
    };
    switch (item.quotationTypeLine) {
      case 'container':
        this.formsForView.newContainer = Object.assign(new Bulk(), item);
        this.activeTab = 0;
        break;
      case 'box':
        this.formsForView.newBox = Object.assign(new Bulk(), item);
        this.activeTab = 1;
        break;
      case 'bulk':
        this.formsForView.newBulk = Object.assign(new Bulk(), item);
        this.activeTab = 2;
        break;
      case 'reference':
        this.formsForView.newReference = Object.assign(new Bulk(), item);
        this.activeTab = 3;
        break;
      default:
        return;
    }
    this.$toasted.show(
      `${this.$t('general.loadedToSection', { section: this.$t(`general.${item.quotationTypeLine}`) })}`
    );
    this.$bvModal.show('mdShowItem');
  }

  /**
   * @description Funcion para enviar al usuario a la lista de registros, dependiendo del modulo
   * @argument {string} listRoute viene desde el componente padre como una prop y contiene el nombre de la ruta a la que se debe redirigir
   * @memberof PreRegisterForm
   * @returns void
   */
  sendBackToList() {
    this.$router.push({ name: this?.listRoute ?? 'historyPanel' });
  }

  async findCompanyById() {
    try {
      this.isLoading = true;
      const companyId = localStorage.getItem('businessId') as string;
      const res: any = await this.findByIdCompany.execute(companyId);
      this.isLoading = false;
      return res;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  /**
   * @description Funcion para obtener la informacion de la empresa
   * @returns void
   * @memberof PreRegisterForm
   * @throws {Error}
   */
  async getCompanyInfo() {
    try {
      this.isLoading = true;
      const companyId = localStorage.getItem('businessId') as string;
      const res: any = await this.findByIdCompany.execute(companyId);
      this.isLoading = false;
      this.companyName = res.businessName;
      this.companyAdress = res.address;
      this.companyPhoneNumber = res.phoneNumber;
      this.companyEmail = res.email;
      this.companyZipCode = res.zipCode;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  /**
   * @description Funcion para obtener todos los tipos de documentos
   * @returns void
   * @memberof PreRegisterForm
   * @throws {Error}
   */
  async getTypeDocument() {
    try {
      this.isLoading = true;
      const res = await this.findAllTypeDocument.execute();
      this.typeDocumentList = res.length > 0 ? res : [];

      if (this.switchList) {
        this.typeDocumentList = this.typeDocumentList.filter((item: TypeDocument) => {
          return this.switchList?.includes(item.sw);
        });
      }

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  /**
   * @description Funcion para obtener las bodegas de un almacén
   * @param {Warehouse} warehouse
   * @returns void
   * @memberof PreRegisterForm
   */
  async getStoresByWarehouseCode(warehouse: Warehouse) {
    try {
      if (warehouse == null) return;

      this.isLoading = true;
      const res = await this.findAllStoreWarehouse.execute(warehouse?.code);
      this.storeByWarehouseList = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  /**
   * @description Funcion para obtener todos los consignee que coincidan con el query
   * @param {string} query
   * @returns void
   */
  async findConsignee(query: string) {
    try {
      this.isLoading = true;
      const res = await this.findConsigneeByName.execute(query.trim().toLocaleLowerCase());
      this.consigneeList = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  findConsigneeFactory(query: string) {
    this.timeoutConsignee && clearTimeout(this.timeoutConsignee);

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    this.timeoutConsignee = setTimeout(async () => {
      if (query.length < 2) return;

      await this.findConsignee(query);
    }, this.searchInterval);
  }

  handleConsignee(item: Entity): void {
    this.form.consignee = Object.assign({}, item);
  }

  async printFunction() {
    this.isLoading = true;
    const element = document.getElementById('taggPrint2');
    const opt = {
      margin: 0.5,
      filename: `${this.form.trackingNumber?.trackingNumber}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    await html2pdf()
      .set(opt)
      .from(element)
      .save();
    this.isLoading = false;
  }

  /**
   * @description Funcion para obtener los puertos generales a partir de un query
   * @params {string} query
   * @returns void
   * @memberof PreRegisterForm
   * @throws {Error}
   */
  findGeneralPorts(query: string) {
    try {
      clearTimeout(this.timeoutEntity);
      if (query.length < 3) return;
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      this.timeoutEntity = setTimeout(async () => {
        try {
          this.isLoading = true;
          const res = await this.findPortsByQuery.execute(query);
          this.generalPortsList = res.length > 0 ? res : [];
          this.isLoading = false;
          clearTimeout(this.timeoutEntity);
        } finally {
          this.isLoading = false;
        }
      }, this.searchInterval);
    } catch (error) {
      this.isLoading = false;
    }
  }

  @Watch('form.store')
  async getPickedBulks(store: Store | null) {
    try {
      if (!store) return;
      this.isLoading = true;
      const res = await this.findPickedBulks.execute({
        store: store
      });
      this.bulksPicked = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  setWarehouseAndStoreFromBranch(branch: Branch | null) {
    if (!branch) {
      this.form.warehouse = null;
      this.form.store = null;
      return;
    }
    this.form.warehouse = branch.warehouse;
    this.form.store = branch.store;
  }

  get calcTotalCargoWeight() {
    let totalWeight = 0;
    this.form.bulks.forEach(bulk => {
      totalWeight += Number(bulk.weight);
    });
    return totalWeight.toFixed(2);
  }

  selectedReferenceInfo(reference: References) {
    this.referenceSelected = reference;
  }

  deselectReference() {
    this.referenceSelected = null;
  }

  dynamicWarehouseProcessValue() {
    if ((this.$router as any).history.current.path.includes('/reception/')) {
      this.warehouseProcess = 'inbound';
    }
    if ((this.$router as any).history.current.path.includes('/expedition/')) {
      this.warehouseProcess = 'outbound';
    }
  }

  warehouseProcessValue(row: any) {
    this.referenceSelected = row.reference;
    this.$bvModal.show('mdlSerialModal');
  }

  get outboundReference() {
    return this.referenceSelected;
  }

  async findTimelineReferences(timeline: TimelineConfig | null) {
    try {
      if (!timeline) {
        this.asociatedReferences = [];
        return;
      }
      this.isLoading = true;
      const res = await this.findReferencesByTimeline.internalExecute({
        timeline: timeline.id
      });
      this.asociatedReferences = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async showDocument(row: PreRegister) {
    this.$bvModal.show('whrPreview');
    this.preRegisterData = row;
    const DataForWarehouseReceipt = {
      numberDoc: Number(row.number),
      typeDoc: row.type
    };
    const res = await this.findDataForWarehouseReceipt.execute(DataForWarehouseReceipt);
    this.bulksForWarehouseReceipt = res;
  }

  showButton() {
    const currentUrl = window.location.href;
    if (currentUrl.includes('warehouse-receipt')) {
      this.showButtonIfNotPreRegister = true;
    } else {
      this.showButtonIfNotPreRegister = false;
    }
  }

  exportToPDF(id: string) {
    const config = {
      margin: 0.1,
      pagebreak: { mode: 'avoid-all' },
      filename: id,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'in', format: 'legal', orientation: 'p' }
    };
    html2pdf(document.getElementById(id), config);
  }

  async findPreparations() {
    try {
      this.isLoading = true;
      const res = await this.findPreparationByStatus.internalExecute('NOT_USED');
      this.preRegisterPreparations = res;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async onPreparationInput(preparation: PreparationForList | undefined) {
    if (!preparation) return;

    // Agregamos la preparación seleccionada al formulario para que se guarde en la base de datos
    this.form.preRegisterPreparation = preparation.id;

    // Se debe buscar la preparación seleccionada para obtener los bultos asociados
    // Y luego se deben agregar a la lista de bultos del formulario
    try {
      this.isLoading = true;
      const res = await this.findPreparation(preparation.id);

      // Se itera sobre los bultos y se usa el método addBulk para agregarlos a la lista
      //TODO: Verificar el resto de información (Shipper, Customer, Consignee, Address, Commodity, etc) al bulto
      res.bulks.forEach(bulk => {
        bulk.newConsignee = res.consignee;
        bulk.newShipper = res.shipper;
        bulk.customer = res.customer;
        bulk.finalDestination = res.addressLine;
        bulk.commodity = res.commodity;
        this.addBulk(bulk, bulk.quantity, bulk.cargoType);
      });
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async findPreparation(preparationId: string) {
    try {
      return await this.findPreparationById.execute(preparationId);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
